import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import { loadUser } from "actions/auth";
import {
  FRONTEMPLOYER_ERROR,
  FRONTEMPLOYER_UPDATED,
  FRONTEMPLOYER_GET_BY_ID,
  FRONTEMPLOYER_CERTIFICATE_DELETE,
  FRONTEMPLOYER_LOADING_ON_SUBMIT,
  REMOVE_ERRORS,
  FRONTEMPLOYER_TESTORDER_EMPLOYEES_LIST,
  FRONTEMPLOYER_RESET,
  FRONTEMPLOYER_LOAD_PAGE,
  LOGIN_SUCCESS,
  FRONTEMPLOYER_LOGO_UPDATED,
} from "actions/types";
import { logout } from "actions/auth";

// Get Lab by id
export const getEmployerById = (employer_id) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnLabSubmit());
    var res = await axios.get(`/api/employer/${employer_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: FRONTEMPLOYER_GET_BY_ID,
        payload: res.data.response,
      });
    } else {
      dispatch({
        type: FRONTEMPLOYER_ERROR,
        payload: {
          msg: "Something Went Wrong",
          status: res.data.status,
          data: res.data,
        },
      });
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTEMPLOYER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

//  Employer Registration
export const createEmployer = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnLabSubmit());
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.post(`/api/employer/`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.response,
      });
      dispatch(loadUser());
      history.push("/organization");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch({
        type: FRONTEMPLOYER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

// Edit Organization
export const edit = (formData, history, employer_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnLabSubmit());
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.put(
      `/api/employer/${employer_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTEMPLOYER_UPDATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert(res.data.message || "Organization Updated.", "success")
      );
      history.push("/organization/profile");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTEMPLOYER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

// Delete Lab Certificates
export const deleteLabCertificate =
  (employer_id, certificate_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `/api/lab/${employer_id}/certificate/${certificate_id}`,
        config
      );
      dispatch({
        type: FRONTEMPLOYER_CERTIFICATE_DELETE,
        payload: certificate_id,
      });
      dispatch(setAlert("Certificate deleted.", "success"));
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTEMPLOYER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
        }
      }
    }
  };

export const uploadEmployerLogo =
  (employer_id, formData) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let params = new window.FormData();
      for (let i in formData) {
        params.append(i, formData[i]);
      }

      const res = await axios.put(
        `/api/employer/${employer_id}/upload-logo`,
        params,
        config
      );

      if (res.data.status === true) {
        dispatch({
          type: FRONTEMPLOYER_LOGO_UPDATED,
          payload: res.data.response?.logo,
        });
        dispatch(setAlert("Logo updated.", "success"));
      }

      return res?.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTEMPLOYER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
        }
      }
    }
  };

export const getEmployerLogo = (employer_id) => async (dispatch) => {
  try {
    const config = {
      responseType: "blob",
    };

    const res = await axios.get(`/api/employer/${employer_id}/logo`, config);

    return res?.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTEMPLOYER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

// Cancel Lab
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/organization/profile");
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/organization/page-not-found");
};

// Dispatch Loading
export const loadingOnLabSubmit = () => async (dispatch) => {
  await dispatch({ type: FRONTEMPLOYER_LOADING_ON_SUBMIT });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: FRONTEMPLOYER_RESET });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: FRONTEMPLOYER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: FRONTEMPLOYER_LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};
//get employees
export const getEmployeesByEmployer =
  (employer_id, orderId) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(
        `/api/employer/employees-list/${employer_id}`,
        { orderId: orderId },
        config
      );
      dispatch({
        type: FRONTEMPLOYER_TESTORDER_EMPLOYEES_LIST,
        payload: res.data.response,
      });
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err.response);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTEMPLOYER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(setAlert(err.response.message, "danger"));
        }
      }
    }
  };
// NotifyMe for city
export const NotifyMe = (formData) => async (dispatch) => {
  const config = { headers: { "content-type": "application/json" } };
  try {
    const res = await axios.post(`/api/lab/notifyme`, formData, config);
    if (res.data.status === true) {
      dispatch(setAlert("Thank you for giving prospects.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      dispatch({
        type: FRONTEMPLOYER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

export const getConsentFormContentWeb = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    // dispatch({
    //   type: LOADING_CONSENT_FORM
    // });
    var res = await axios.get(`/api/get-consent-form/web`, config);

    if (res.data.status === true) {
    } else {
      dispatch({
        type: FRONTEMPLOYER_ERROR,
        payload: {
          msg: "Something Went Wrong",
          status: res.data.status,
          data: res.data,
        },
      });
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTEMPLOYER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};
