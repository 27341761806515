import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  FRONTPHYSICIAN_TESTORDER_LIST_LOADING,
  FRONTPHYSICIAN_TESTORDER_RESET,
  FRONTPHYSICIAN_TESTORDER_LOAD_PAGE,
  FRONTPHYSICIAN_TESTORDER_ERROR,
  FRONTPHYSICIAN_TESTORDER_LIST_UPDATED,
  FRONTPHYSICIAN_TESTORDER_SEARCH_PARAMATERS_UPDATE,
  FRONTPHYSICIAN_TESTORDER_GET_BY_ID,
  FRONTPHYSICIAN_TESTORDER_LOADING_ON_SUBMIT,
  FRONTPHYSICIAN_TESTORDER__CREATED,
  FRONTPHYSICIAN_TESTORDER_EMPLOYERS_LIST,
  REMOVE_ERRORS,
  FRONTPHYSICIAN_TESTORDER_COMMENT_SUBMIT,
  FRONTPHYSICIAN_TESTORDER_COMMENT_COMPLETE,
  FRONTPHYSICIAN_TESTORDER_COMMENT_UPDATE,
  FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_SUBMIT,
  FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_COMPLETE,
  FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_UPDATE,
  FRONTPHYSICIAN_TESTORDER_MRO_OBSERVATION_UPDATE,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_LOADING,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_TEXT,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR,
  FRONTPHYSICIAN_EMPLOYEE_ERROR,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_ON_SUBMIT,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_SUBMITED,
  FRONTPHYSICIAN_TESTORDER_STATES_LIST,
  UPDATE_ORG_SHORT_INFO,
} from "actions/types";

import { logout } from "actions/auth";

export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: FRONTPHYSICIAN_TESTORDER_RESET });
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: FRONTPHYSICIAN_TESTORDER_LOAD_PAGE });
};

export const loadingOnTestOrderSubmit = () => async (dispatch) => {
  await dispatch({ type: FRONTPHYSICIAN_TESTORDER_LOADING_ON_SUBMIT });
};

// show alertmsg
export const showMsg = (msg, type) => async (dispatch) => {
  dispatch(setAlert(msg, type));
};

//setErrors
export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: FRONTPHYSICIAN_TESTORDER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/physician/orders");
};

//get Employee list
export const getTestOrderList = (physicianParams) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: FRONTPHYSICIAN_TESTORDER_LIST_LOADING });
    physicianParams.query = physicianParams.query ? physicianParams.query : "";
    config.params = physicianParams;
    const res = await axios.get(`/api/physician/orders`, config);
    dispatch({
      type: FRONTPHYSICIAN_TESTORDER_SEARCH_PARAMATERS_UPDATE,
      payload: physicianParams,
    });
    dispatch({
      type: FRONTPHYSICIAN_TESTORDER_LIST_UPDATED,
      payload: res.data.response[0],
    });
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

// Create Test Order
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnTestOrderSubmit());

  const config = {
    headers: {
      "Content-Type": "application/json", // multipart/form-data;
    },
  };

  try {
    const res = await axios.post(`/api/physician/orders`, formData, config);

    if (res.data.status === true) {
      dispatch({
        type: FRONTPHYSICIAN_TESTORDER__CREATED,
        payload: res.data.response,
      });
      dispatch(setAlert(res.data.message || "Order Created.", "success"));

      const createdOrderIDs = res.data.response;
      if (!createdOrderIDs.length) {
        history.push("/physician/orders");
      } else if (createdOrderIDs.length > 1) {
        history.push("/physician/orders");
      } else {
        history.push(`/physician/orders/${createdOrderIDs[0]}/edit`);
      }
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTPHYSICIAN_TESTORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTPHYSICIAN_TESTORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

//get employers list
export const getEmployersList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`/api/physician/organizations/list`, config);

    dispatch({
      type: FRONTPHYSICIAN_TESTORDER_EMPLOYERS_LIST,
      payload: res.data.response,
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTPHYSICIAN_TESTORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  }
};

export const getOrderById = (test_order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());
    const res = await axios.get(
      `/api/physician/orders/${test_order_id}`,
      config
    );
    await dispatch({
      type: FRONTPHYSICIAN_TESTORDER_GET_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err.response);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: FRONTPHYSICIAN_TESTORDER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get result file
export const getResultAction =
  (order_id, member_id, result_id) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.get(
        `/api/physician/orders/${order_id}/get-result/${member_id}/${result_id}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "Either the file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

//Get remove Timer of Zipping of patient's documents
export const removeTimerForZipPatientDocuments =
  (order_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `/api/physician/orders/${order_id}/removeTimerForZipPatientDocuments`,
        config
      );
      return;
    } catch (err) {
      console.log(err);
    }
  };

//Get start Time of Zipping of patient's documents
export const getInitiatedTimeForZipPatientDocuments =
  (order_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `/api/physician/orders/${order_id}/getInitiatedTimeForZipPatientDocuments`,
        config
      );
      return res.data.response;
    } catch (err) {
      console.log(err);
    }
  };

//Initiate Zipping of patient's documents
export const initiateZipPatientDocuments =
  (order_id, patients) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/physician/orders/${order_id}/initiateZipPatientDocuments`,
        { patients },
        config
      );
      if (res.data.status === true) {
        console.log(res.data.message);
        dispatch(setAlert(res.data.message, "success"));
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const addCommentToPatient =
  (formData, test_order_id) => async (dispatch) => {
    try {
      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_COMMENT_SUBMIT,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/physician/orders/${test_order_id}/add-comment`,
        formData,
        config
      );

      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_COMMENT_COMPLETE,
      });

      if (res.data.status === true) {
        await dispatch({
          type: FRONTPHYSICIAN_TESTORDER_COMMENT_UPDATE,
          payload: formData,
        });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
      }
    } catch (err) {
      // console.log("In catch section", err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const addProviderObservationForPatient =
  (formData, test_order_id) => async (dispatch) => {
    try {
      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_SUBMIT,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/physician/orders/${test_order_id}/provider-observation`,
        formData,
        config
      );

      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_COMPLETE,
      });

      if (res.data.status === true) {
        await dispatch({
          type: FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_UPDATE,
          payload: formData,
        });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
      }
    } catch (err) {
      // console.log("In catch section", err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const addMROObservationForPatient =
  (formData, test_order_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/physician/orders/${test_order_id}/MRO-observation`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch({
          type: FRONTPHYSICIAN_TESTORDER_MRO_OBSERVATION_UPDATE,
          payload: formData,
        });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        console.log("error msg", res.data.errors[0].msg);
        dispatch(setAlert(res.data.errors[0].msg, "danger"));
      }
    } catch (err) {
      // console.log("In catch section", err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// get consent form
export const getConsentForm = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTPHYSICIAN_EMPLOYEE_CONSENT_LOADING,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.get(`/api/get-consent-form/web`, config);
    if (res.data.status === true) {
      dispatch({
        type: FRONTPHYSICIAN_EMPLOYEE_CONSENT_TEXT,
        payload: res.data.response,
      });

      return res.data.response;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTPHYSICIAN_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Save consent form
export const saveConsentForm = (formData, patientID) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTPHYSICIAN_EMPLOYEE_CONSENT_ON_SUBMIT,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.put(
      `/api/patient/${patientID}/save-consent-form`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTPHYSICIAN_EMPLOYEE_CONSENT_SUBMITED,
        payload: formData.signatureImgURL,
      });
      dispatch(setAlert(res.data.message, "success"));
      return true;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTPHYSICIAN_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

const get_params = (formData) => {
  let params = new window.FormData();
  const formFields = [
    "registrationType",
    "line_1",
    "line_2",
    "zip_code",
    "user_id",
    "iso",
    "secondaryUsername",
    "fullName",
    "phone",
    "email",
    "dob",
    "ccode",
    "employerId",
    "isChild",
    "paymentBy",
    "gender",
    "insuranceCompany",
    "subscriberId",
    "parentsApproval",
    "collectInsuranceData",
    "insuranceDetails",
    "isPregnant",
    "referralAuthority",
    "icdValidCode",
    "ethnicity",
    "ssn",
    "jobNumber",
    "race",
    "alternativePhone",
    "alternativePhoneISO",
    "alternativePhoneCCode",
    "alternativePhone_full",
    "occupation",
    "employedInHealthcare",
    "memberPassword",
    "vaccineType",
    "firstDoseDone",
    "firstDoseDate",
    "secondDoseDone",
    "secondDoseDate",
    "boosterDone",
    "boosterDate",
    "vaccinationCardFront",
    "vaccinationCardBack",
    "signatureImgURL",
  ];
  const formObjectFields = [
    "a_city",
    "primaryInsurance",
    "secondaryInsurance",
    "companyLocation",
  ];
  formFields.forEach((field) => {
    if (formData[field]) {
      params.append(field, formData[field]);
    }
  });
  formObjectFields.forEach((field) => {
    if (formData[field]) {
      if (field === "companyLocation")
        params.append(field, formData[field].value);
      else if (field === "primaryInsurance" || field === "secondaryInsurance") {
        Object.keys(formData[field]).forEach((item) => {
          params.append(field + "[" + item + "]", formData[field][item]);
        });
      } else params.append(field, JSON.stringify(formData[field]));
    }
  });

  return params;
};

// Patient Registration
export const registerPhysicianPatient = (formData) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  // dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.post(
      `/api/physician/orders/register-patient`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch(
        setAlert(
          "Registration successful. An email has been sent to you with the login information.",
          "success"
        )
      );
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTPHYSICIAN_TESTORDER_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Get Employer short info by id
export const getPhysicianEmployerShortInfoById = (id) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    dispatch({ type: REMOVE_ERRORS });
    let res = await axios.get(
      `/api/physician/orders/short-info-org/${id}`,
      config
    );

    await dispatch({
      type: UPDATE_ORG_SHORT_INFO,
      payload: res.data.response,
    });
    return res.data.response;
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTPHYSICIAN_TESTORDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });

    if (err.response && err.response.status === 401) {
      dispatch(setAlert("Invalid Access", "danger"));
    }

    return err?.response;
  }
};

// Edit test order
export const saveOrderEmployerInfo =
  (formData, test_order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/physician/orders/${test_order_id}/employer`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: FRONTPHYSICIAN_TESTORDER_GET_BY_ID,
          payload: res.data.response,
        });
        dispatch(
          setAlert(
            res.data.message || "Organization information updated.",
            "success"
          )
        );
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTPHYSICIAN_TESTORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const saveOrderPatientData =
  (formData, order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/physician/orders/${order_id}/patient-info`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: FRONTPHYSICIAN_TESTORDER_GET_BY_ID,
          payload: res.data.response,
        });
        dispatch(
          setAlert(res.data.message || "Order patient info Updated.", "success")
        );
        // history.push("/admin/orders");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTPHYSICIAN_TESTORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const saveOrderProductsInfo =
  (formData, order_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnTestOrderSubmit());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/physician/orders/${order_id}/product-info`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: FRONTPHYSICIAN_TESTORDER_GET_BY_ID,
          payload: res.data.response,
        });
        dispatch(setAlert(res.data.message || "Order Updated.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTPHYSICIAN_TESTORDER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: FRONTPHYSICIAN_TESTORDER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const getStatesList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    var res = await axios.get(`/api/states`, config);
    if (res.data.status === true) {
      await dispatch({
        type: FRONTPHYSICIAN_TESTORDER_STATES_LIST,
        payload: res.data.response,
      });
    } else {
      console.log("getStatesList res.data", res.data);
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
  }
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/admin/page-not-found");
};
