import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  FRONTEMPLOYER_EMPLOYEE_LIST_LOADING,
  FRONTEMPLOYER_EMPLOYEE_RESET,
  FRONTEMPLOYER_EMPLOYEE_LOAD_PAGE,
  // FRONTEMPLOYER_LOAD_PAGE,
  FRONTEMPLOYER_EMPLOYEE_ERROR,
  FRONTEMPLOYER_ERROR,
  FRONTEMPLOYER_EMPLOYEE_CHANGE_STATUS,
  FRONTEMPLOYER_EMPLOYEE_CREATED,
  FRONTEMPLOYER_EMPLOYEE_LOADING_ON_SUBMIT,
  FRONTEMPLOYER_EMPLOYEE_GET_BY_ID,
  FRONTEMPLOYER_EMPLOYEE_UPDATED,
  FRONTEMPLOYER_EMPLOYEE_UPLOADED,
  FRONTEMPLOYER_EMPLOYEE_LIST_UPDATED,
  FRONTEMPLOYER_EMPLOYEE_DELETE,
  FRONTEMPLOYER_EMPLOYEE_SEARCH_PARAMATERS_UPDATE,
  FRONTEMPLOYER_EMPLOYEE_INSURANCE_ON_SUBMIT,
  FRONTEMPLOYER_EMPLOYEE_INSURANCE_UPDATED,
  REMOVE_ERRORS,
  UPDATE_ORG_SHORT_INFO,
} from "actions/types";

//get Employee list
export const getEmployeeList = (employeeParams) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: FRONTEMPLOYER_EMPLOYEE_LIST_LOADING });
    employeeParams.query = employeeParams.query ? employeeParams.query : "";
    config.params = employeeParams;

    const organizationId = sessionStorage.getItem("organizationId");
    const res = await axios.get(
      `/api/employer/employee/list/${organizationId}`,
      config
    );
    dispatch({
      type: FRONTEMPLOYER_EMPLOYEE_SEARCH_PARAMATERS_UPDATE,
      payload: employeeParams,
    });
    dispatch({
      type: FRONTEMPLOYER_EMPLOYEE_LIST_UPDATED,
      payload: res.data.response[0],
    });
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

// Get Phlebotomist by id
export const getEmployeeById =
  (id, uid = "") =>
  async (dispatch) => {
    try {
      dispatch(loadingOnEmployeeSubmit());
      const config = { headers: { "Content-Type": "application/json" } };
      dispatch({ type: REMOVE_ERRORS });
      let res;
      if (uid === "") {
        res = await axios.get(`/api/employer/employee/${id}`, config);
      } else {
        res = await axios.get(`/api/employer/employee/${id}?uid=uid`, config);
      }
      await dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_GET_BY_ID,
        payload: res.data.response,
      });
      return res.data.response;
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch({
          type: FRONTEMPLOYER_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
    }
  };

// Get parient insurance card by id
export const getInsuranceCard = (patient_id) => async (dispatch) => {
  try {
    const config = { responseType: "blob" };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/employer/employee/${patient_id}/get-insurance-card?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "The insurance card file does not exist or something went wrong.",
        "danger"
      )
    );
  }
};

const get_params = (formData) => {
  let params = new window.FormData();
  const formFields = [
    "registrationType",
    "line_1",
    "line_2",
    "zip_code",
    "user_id",
    "iso",
    "secondaryUsername",
    "fullName",
    "phone",
    "email",
    "dob",
    "ccode",
    "employerId",
    "isChild",
    "paymentBy",
    "gender",
    "insuranceCompany",
    "subscriberId",
    "parentsApproval",
    "collectInsuranceData",
    "insuranceDetails",
    "isPregnant",
    "referralAuthority",
    "icdValidCode",
    "ethnicity",
    "ssn",
    "jobNumber",
    "race",
    "alternativePhone",
    "alternativePhoneISO",
    "alternativePhoneCCode",
    "alternativePhone_full",
    "occupation",
    "employedInHealthcare",
    "memberPassword",
    "vaccineType",
    "firstDoseDone",
    "firstDoseDate",
    "secondDoseDone",
    "secondDoseDate",
    "boosterDone",
    "boosterDate",
    "vaccinationCardFront",
    "vaccinationCardBack",
    "signatureImgURL",
  ];
  const formObjectFields = [
    "a_city",
    "primaryInsurance",
    "secondaryInsurance",
    "companyLocation",
  ];
  formFields.forEach((field) => {
    if (formData[field]) {
      params.append(field, formData[field]);
    }
  });
  formObjectFields.forEach((field) => {
    if (formData[field]) {
      if (field === "companyLocation")
        params.append(field, formData[field].value);
      else if (field === "primaryInsurance" || field === "secondaryInsurance") {
        Object.keys(formData[field]).forEach((item) => {
          params.append(field + "[" + item + "]", formData[field][item]);
        });
      } else params.append(field, JSON.stringify(formData[field]));
    }
  });

  return params;
};

// Create Employee
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "content-type": "multipart/form-data;",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.post(`/api/employer/employee`, params, config);
    if (res.data.status === true) {
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_CREATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert(
          formData.email || formData.phone
            ? "Patient created and notified to verify the information."
            : "Patient created.",
          "success"
        )
      );
      history.push("/organization/patients");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_EMPLOYEE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Edit Employee
export const edit = (formData, history, employee_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.put(
      `/api/employer/employee/${employee_id}`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Patient Updated.", "success"));
      history.push("/organization/patients");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_EMPLOYEE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          // if(error.param==="description")
          // dispatch(setAlert(error.msg, "danger"));
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

//upload employee via csv
export const UploadEmployeeCsv = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmployeeSubmit());

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  try {
    let params = new window.FormData();
    params.append("employerId", formData["employerId"]);
    params.append(
      "csvFile",
      formData["selectedFile"],
      formData["selectedFile"].name
    );
    params.append("mapping", JSON.stringify(formData["mapping"]));
    params.append("generateMapping", formData["generateMapping"]);
    const res = await axios.post(
      `/api/employer/employee/upload-employee`,
      params,
      config
    );
    if (res.data.status === true) {
      if (!res.data.response.headers) {
        dispatch({
          type: FRONTEMPLOYER_EMPLOYEE_UPLOADED,
          payload: res.data.response,
        });
        dispatch(setAlert(res.data.message || "Patient Created.", "success"));
      } else {
        dispatch(loadPage());
      }
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_EMPLOYEE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }

    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        // dispatch(logout());
      } else {
        dispatch({
          type: FRONTEMPLOYER_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const verifyPatientInformation =
  (formData, employee_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnEmployeeSubmit());
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      const params = get_params(formData);
      const res = await axios.post(
        `/api/employer/employee/varify-patient-information/${employee_id}`,
        params,
        config
      );
      if (res.data.status === true) {
        dispatch(
          setAlert(
            "Thank you for registering with iConnectUs. You will be notified with your test result.",
            "success"
          )
        );
        // history.push("/login");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTEMPLOYER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            // if(error.param==="description")
            // dispatch(setAlert(error.msg, "danger"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch({
          type: FRONTEMPLOYER_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  };

// Delete Employee
export const deleteEmployee = (employerId, employee_id) => async (dispatch) => {
  try {
    await dispatch({ type: FRONTEMPLOYER_EMPLOYEE_LIST_LOADING });
    const config = { headers: { "Content-Type": "application/json" } };

    const res = await axios.delete(
      `/api/employer/employee/${employee_id}?organizationId=${employerId}`,
      config
    );

    dispatch({
      type: FRONTEMPLOYER_EMPLOYEE_DELETE,
      payload: employee_id,
    });
    dispatch(setAlert(res.data.message || "Patient deleted.", "success"));
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });

    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

//change status
export const changeStatus = (employee_id, formData) => async (dispatch) => {
  try {
    await dispatch({ type: FRONTEMPLOYER_EMPLOYEE_LIST_LOADING });
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.put(
      `/api/employer/employee/${employee_id}/status`,
      formData,
      config
    );

    await dispatch({
      type: FRONTEMPLOYER_EMPLOYEE_CHANGE_STATUS,
      payload: res.data.response,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

// Cancel Phlebotomist
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/organization/patients");
};

// Dispatch Loading
export const loadingOnEmployeeSubmit = () => async (dispatch) => {
  await dispatch({ type: FRONTEMPLOYER_EMPLOYEE_LOADING_ON_SUBMIT });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: FRONTEMPLOYER_EMPLOYEE_RESET });
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: FRONTEMPLOYER_EMPLOYEE_LOAD_PAGE });
};

// show alertmsg
export const showMsg = (msg, type) => async (dispatch) => {
  dispatch(setAlert(msg, type));
};

//setErrors
export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: FRONTEMPLOYER_EMPLOYEE_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

const getInsuranceParams = (formData) => {
  let params = new window.FormData();
  Object.keys(formData).forEach((field) => {
    params.append(field, formData[field]);
  });

  return params;
};

// Save Insurance data
export const saveInsuranceData =
  (formData, employee_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });

    dispatch({
      type: FRONTEMPLOYER_EMPLOYEE_INSURANCE_ON_SUBMIT,
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      const params = getInsuranceParams(formData);
      const res = await axios.put(
        `/api/employer/employee/${employee_id}/save-insurance-data`,
        params,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: FRONTEMPLOYER_EMPLOYEE_INSURANCE_UPDATED,
          payload: res.data.response,
        });
        dispatch(setAlert("Insurance data updated.", "success"));
        // history.push("/organization/patients");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTEMPLOYER_EMPLOYEE_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            // if(error.param==="description")
            // dispatch(setAlert(error.msg, "danger"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch({
          type: FRONTEMPLOYER_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  };

// Get parient insurance card front by id
export const getInsuranceCardFront =
  (patient_id, insuranceType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        insuranceType === "primary"
          ? "get-primary-insurance-card/front"
          : "get-secondary-insurance-card/front";
      const res = await axios.get(
        `/api/employer/employee/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The insurance card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get parient insurance card back by id
export const getInsuranceCardBack =
  (patient_id, insuranceType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        insuranceType === "primary"
          ? "get-primary-insurance-card/back"
          : "get-secondary-insurance-card/back";
      const res = await axios.get(
        `/api/employer/employee/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The insurance card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get Insurance Name List
export const getInsuranceNameList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get(
      `/api/employer/employee/insuranceNameList`,
      config
    );
    return res.data.response;
  } catch (err) {
    console.log(err);
  }
};

// Get vaccination card
export const getVaccinationCard =
  (patient_id, cardType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        cardType === "front"
          ? "get-vaccination-card/front"
          : "get-vaccination-card/back";
      const res = await axios.get(
        `/api/employer/employee/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The vaccination card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Patient Registration
export const registerPatient = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.post(
      `/api/employer/employee/register-patient`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_CREATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert(
          "Registration successful. An email has been sent to you with the login information.",
          "success"
        )
      );
      // history.push("/organization/patients");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTEMPLOYER_EMPLOYEE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Get consent file from patient records
export const getConsentPatientAction = (patient_id) => async (dispatch) => {
  try {
    const config = { responseType: "blob" };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/employer/employee/${patient_id}/get-consent-patient/?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "Either the file does not exist or something went wrong.",
        "danger"
      )
    );
  }
};

// Get Employer short info by id
export const getEmployerShortInfoById = (id) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    dispatch({ type: REMOVE_ERRORS });
    let res = await axios.get(
      `/api/employer/employee/short-info-org/${id}`,
      config
    );

    await dispatch({
      type: UPDATE_ORG_SHORT_INFO,
      payload: res.data.response,
    });
    return res.data.response;
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTEMPLOYER_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};
