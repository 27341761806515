import React from "react";
import { Row, Col, FormGroup, Label, Input, Table } from "reactstrap";
import { getImmigrationFieldValue } from "utils/immgrationHelper";

const Page14 = ({
  formData1,
  formData2,
  formData14,
  setFormData14,
  onChangeAdditionalInformation,
}) => {
  const { language, familyName, givenName, middleName } = formData1;
  const { aNumber } = formData2;
  const { additionalInformation } = formData14;

  return (
    <React.Fragment>
      <div className="immgration-form-header mb-3 p-3">
        <h4 className="m-0 p-0">
          {getImmigrationFieldValue("part_11_additional_information", language)}
        </h4>
      </div>
      <Row>
        <Col sm="12">
          <div
            dangerouslySetInnerHTML={{
              __html: getImmigrationFieldValue("part_11_description", language),
            }}
            className="my-2"
          ></div>
        </Col>

        <Col sm={12}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="familyName">
                  1. {getImmigrationFieldValue("family_name", language)}
                </Label>
                <Input
                  className="ml-3"
                  type="text"
                  name="familyName"
                  value={familyName}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="givenName">
                  {getImmigrationFieldValue("given_name", language)}
                </Label>
                <Input
                  type="text"
                  name="givenName"
                  disabled
                  value={givenName}
                />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="middleName">
                  {getImmigrationFieldValue("middle_name", language)}
                </Label>
                <Input
                  type="text"
                  name="middleName"
                  disabled
                  value={middleName}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col md={6}>
          <Row>
            <Col>
              <FormGroup className="d-flex">
                <Label for="aNumber" className="w-25">
                  2. {getImmigrationFieldValue("a_number_if_any", language)}
                </Label>
                <Input type="text" name="aNumber" value={aNumber} disabled />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col md={12}>
          {[3, 4, 5].map((sectionNumber, i) => (
            <FormGroup key={sectionNumber}>
              <Row>
                <Col md={2}>
                  <Label>
                    {sectionNumber} A.{" "}
                    {getImmigrationFieldValue("page_number_a", language)}
                  </Label>
                  <Input
                    className="ml-3"
                    type="text"
                    name="page_number"
                    value={additionalInformation[i]?.page_number}
                    onChange={(e) => onChangeAdditionalInformation(e, i)}
                  />
                </Col>
                <Col md={2}>
                  <Label>
                    B. {getImmigrationFieldValue("part_number_b", language)}
                  </Label>
                  <Input
                    className="ml-3"
                    type="text"
                    name="part_number"
                    value={additionalInformation[i]?.part_number}
                    onChange={(e) => onChangeAdditionalInformation(e, i)}
                  />
                </Col>
                <Col md={2}>
                  <Label>
                    C. {getImmigrationFieldValue("item_number_c", language)}
                  </Label>
                  <Input
                    className="ml-3"
                    type="text"
                    name="item_number"
                    value={additionalInformation[i]?.item_number}
                    onChange={(e) => onChangeAdditionalInformation(e, i)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Label for={`additionalInfo${sectionNumber}`}>D.</Label>
                  <Input
                    type="textarea"
                    name="description"
                    value={additionalInformation[i]?.description}
                    onChange={(e) => onChangeAdditionalInformation(e, i)}
                  />
                </Col>
              </Row>
            </FormGroup>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Page14;
