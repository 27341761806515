import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  FRONTSTAFF_EMPLOYEE_RESET,
  FRONTSTAFF_EMPLOYEE_LOAD_PAGE,
  FRONTSTAFF_EMPLOYEE_ERROR,
  FRONTSTAFF_EMPLOYEE_LOADING_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_GET_BY_ID,
  FRONTSTAFF_EMPLOYEE_CREATED,
  FRONTSTAFF_EMPLOYEE_UPDATED,
  FRONTSTAFF_EMPLOYEE_INSURANCE_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_INSURANCE_UPDATED,
  FRONTSTAFF_EMPLOYEE_ORDER,
  REMOVE_ERRORS,
  FRONTSTAFF_EMPLOYEE_BARCODE_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_BARCODE_SAVED,
  FRONTSTAFF_EMPLOYEE_BARCODE_ERROR,
  FRONTSTAFF_EMPLOYEE_HEALTH_LOADING,
  FRONTSTAFF_EMPLOYEE_HEALTH_QUESTIONS,
  FRONTSTAFF_EMPLOYEE_HEALTH_ERROR,
  FRONTSTAFF_EMPLOYEE_HEALTH_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_HEALTH_SUBMITED,
  FRONTSTAFF_EMPLOYEE_CONSENT_LOADING,
  FRONTSTAFF_EMPLOYEE_CONSENT_ERROR,
  FRONTSTAFF_EMPLOYEE_CONSENT_TEXT,
  FRONTSTAFF_EMPLOYEE_CONSENT_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_CONSENT_SUBMITED,
  FRONTSTAFF_EMPLOYEE_LOADING_DONE,
  FRONTSTAFF_EMPLOYEE_IMMIGRATION_UPDATED,
  FRONTSTAFF_EMPLOYEE_IMMIGRATION_DATA_UPDATED,
  FRONTEMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED,
} from "actions/types";

// Get Patient by id
export const getEmployeeById = (order_id, patient_id) => async (dispatch) => {
  try {
    dispatch(loadingOnEmployeeSubmit());
    const config = { headers: { "Content-Type": "application/json" } };
    dispatch({ type: REMOVE_ERRORS });

    const res = await axios.get(
      `/api/staff/orders/${order_id}/member/${patient_id}/web`,
      config
    );

    dispatch({
      type: FRONTSTAFF_EMPLOYEE_ORDER,
      payload: res.data.response.order,
    });

    dispatch({
      type: FRONTSTAFF_EMPLOYEE_GET_BY_ID,
      payload: res.data.response.member,
    });

    return res.data.response;
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

// Get parient insurance card by id
export const getInsuranceCard = (patient_id) => async (dispatch) => {
  try {
    const config = { responseType: "blob" };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/employer/employee/${patient_id}/get-insurance-card?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "The insurance card file does not exist or something went wrong.",
        "danger"
      )
    );
  }
};

const get_params = (formData) => {
  let params = new window.FormData();
  const formFields = [
    "registrationType",
    "line_1",
    "line_2",
    "zip_code",
    "user_id",
    "iso",
    "secondaryUsername",
    "fullName",
    "phone",
    "email",
    "dob",
    "ccode",
    "employerId",
    "isChild",
    "paymentBy",
    "gender",
    "insuranceCompany",
    "subscriberId",
    "parentsApproval",
    "collectInsuranceData",
    "insuranceDetails",
    "isPregnant",
    "referralAuthority",
    "icdValidCode",
    "ethnicity",
    "ssn",
    "jobNumber",
    "race",
    "alternativePhone",
    "alternativePhoneISO",
    "alternativePhoneCCode",
    "alternativePhone_full",
    "occupation",
    "employedInHealthcare",
    "memberPassword",
    "vaccineType",
    "firstDoseDone",
    "firstDoseDate",
    "secondDoseDone",
    "secondDoseDate",
    "boosterDone",
    "boosterDate",
    "vaccinationCardFront",
    "vaccinationCardBack",
    "signatureImgURL",
  ];
  const formObjectFields = [
    "a_city",
    "primaryInsurance",
    "secondaryInsurance",
    "companyLocation",
  ];
  formFields.forEach((field) => {
    if (formData[field]) {
      params.append(field, formData[field]);
    }
  });
  formObjectFields.forEach((field) => {
    if (formData[field]) {
      if (field === "companyLocation")
        params.append(field, formData[field].value);
      else if (field === "primaryInsurance" || field === "secondaryInsurance") {
        Object.keys(formData[field]).forEach((item) => {
          params.append(field + "[" + item + "]", formData[field][item]);
        });
      } else params.append(field, JSON.stringify(formData[field]));
    }
  });
  /* if (formData["insuranceCard"]) {
    params.append(
      "insuranceCard",
      formData["insuranceCard"],
      formData["insuranceCard"].name);
    } */
  // console.log("FormData -> ", formData)
  return params;
};

export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Edit Employee
export const edit =
  (formData, history, order_id, employee_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnEmployeeSubmit());
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      const params = get_params(formData);
      const res = await axios.put(
        `/api/staff/orders/${order_id}/member/${employee_id}/web`,
        params,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_UPDATED,
          payload: res.data.response,
        });
        dispatch(setAlert("Patient Updated.", "success"));
        // history.push(`/staff/test-order/${order_id}/view`);
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTSTAFF_EMPLOYEE_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            // if(error.param==="description")
            // dispatch(setAlert(error.msg, "danger"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  };

// Cancel Phlebotomist
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/organization/patients");
};

// Dispatch Loading
export const loadingOnEmployeeSubmit = () => async (dispatch) => {
  await dispatch({ type: FRONTSTAFF_EMPLOYEE_LOADING_ON_SUBMIT });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: FRONTSTAFF_EMPLOYEE_RESET });
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: FRONTSTAFF_EMPLOYEE_LOAD_PAGE });
};

// show alertmsg
export const showMsg = (msg, type) => async (dispatch) => {
  dispatch(setAlert(msg, type));
};

//setErrors
export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: FRONTSTAFF_EMPLOYEE_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

//setAlert
export const dispatchAlert = (msg) => async (dispatch) => {
  dispatch(setAlert(msg ?? "Something went wrong.", "danger"));
};

const getInsuranceParams = (formData) => {
  let params = new window.FormData();
  Object.keys(formData).forEach((field) => {
    params.append(field, formData[field]);
  });

  return params;
};

// Save Insurance data
export const saveInsuranceData =
  (formData, order_id, employee_id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });

    dispatch({
      type: FRONTSTAFF_EMPLOYEE_INSURANCE_ON_SUBMIT,
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      const params = getInsuranceParams(formData);
      const res = await axios.put(
        `/api/staff/orders/${order_id}/member/${employee_id}/save-insurance-data`,
        params,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_INSURANCE_UPDATED,
          payload: res.data.response,
        });
        dispatch(setAlert("Insurance data updated.", "success"));
        // history.push("/organization/patients");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: FRONTSTAFF_EMPLOYEE_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            // if(error.param==="description")
            // dispatch(setAlert(error.msg, "danger"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  };

// Get parient insurance card front by id
export const getInsuranceCardFront =
  (patient_id, insuranceType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        insuranceType === "primary"
          ? "get-primary-insurance-card/front"
          : "get-secondary-insurance-card/front";
      const res = await axios.get(
        `/api/employer/employee/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The insurance card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get parient insurance card back by id
export const getInsuranceCardBack =
  (patient_id, insuranceType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        insuranceType === "primary"
          ? "get-primary-insurance-card/back"
          : "get-secondary-insurance-card/back";
      const res = await axios.get(
        `/api/employer/employee/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The insurance card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get Insurance Name List
export const getInsuranceNameList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get(
      `/api/employer/employee/insuranceNameList`,
      config
    );
    return res.data.response;
  } catch (err) {
    console.log(err);
  }
};

// Get vaccination card
export const getVaccinationCard =
  (patient_id, cardType) => async (dispatch) => {
    try {
      const config = { responseType: "blob" };
      dispatch({ type: REMOVE_ERRORS });
      let routeName =
        cardType === "front"
          ? "get-vaccination-card/front"
          : "get-vaccination-card/back";
      const res = await axios.get(
        `/api/employer/employee/${patient_id}/${routeName}?timestamp=${Date.now()}`,
        config
      );

      return res.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "The vaccination card file does not exist or something went wrong.",
          "danger"
        )
      );
    }
  };

// Get consent file from patient records
export const getConsentPatientAction = (patient_id) => async (dispatch) => {
  try {
    const config = { responseType: "blob" };
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.get(
      `/api/employer/employee/${patient_id}/get-consent-patient/?timestamp=${Date.now()}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch(
      setAlert(
        "Either the file does not exist or something went wrong.",
        "danger"
      )
    );
  }
};

// Save Barcode
export const saveBarcode = (formData, order_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTSTAFF_EMPLOYEE_BARCODE_ON_SUBMIT,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/staff/orders/${order_id}/barcode`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_BARCODE_SAVED,
        payload: formData.barcode,
      });
      dispatch(setAlert(res.data.message, "success"));
      return formData.barcode;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_BARCODE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: FRONTSTAFF_EMPLOYEE_BARCODE_ERROR });

    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// get health checkup questions
export const getHealthCheckupQuestions = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTSTAFF_EMPLOYEE_HEALTH_LOADING,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.get(`/api/get-screening-questions`, config);
    if (res.data.status === true) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_HEALTH_QUESTIONS,
        payload: res.data.response,
      });

      return res.data.response;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_HEALTH_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Save Health Checkup
export const saveHealthCheckup = (formData, order_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTSTAFF_EMPLOYEE_HEALTH_ON_SUBMIT,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/staff/orders/${order_id}/screeningQuestions`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_HEALTH_SUBMITED,
        payload: formData.screeningAns,
      });
      dispatch(setAlert(res.data.message, "success"));
      return true;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_HEALTH_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// get consent form
export const getConsentForm = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTSTAFF_EMPLOYEE_CONSENT_LOADING,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.get(`/api/get-consent-form/web`, config);
    if (res.data.status === true) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_CONSENT_TEXT,
        payload: res.data.response,
      });

      return res.data.response;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_CONSENT_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Save consent form
export const saveConsentForm = (formData, patientID) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: FRONTSTAFF_EMPLOYEE_CONSENT_ON_SUBMIT,
  });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.put(
      `/api/patient/${patientID}/save-consent-form`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_CONSENT_SUBMITED,
        payload: formData.signatureImgURL,
      });
      dispatch(setAlert(res.data.message, "success"));
      return true;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_CONSENT_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return false;
    }
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Create Patient
export const create = (formData, order_id, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const params = get_params(formData);
    const res = await axios.post(
      `/api/staff/orders/${order_id}/create-member`,
      params,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_CREATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Patient Created.", "success"));
      history.push(`/staff/test-order/${order_id}/view`);
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Find Patient
export const findPatient = (formData, order_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmployeeSubmit());
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/staff/orders/${order_id}/find-member`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch({ type: FRONTSTAFF_EMPLOYEE_ERROR });
      dispatch(
        setAlert(
          res.data.message === "Multiple patients found"
            ? "One or more patients found"
            : res.data.message,
          "danger"
        )
      );
    }
    return res.data;
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
  }
};

// Associate Patient
export const associatePatient =
  (formData, order_id, history) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnEmployeeSubmit());
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `/api/staff/orders/${order_id}/associate-member`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_LOADING_DONE });
        dispatch(setAlert(res.data.message, "success"));
        history.push(`/staff/test-order/${order_id}/view`);
      } else {
        dispatch({ type: FRONTSTAFF_EMPLOYEE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res.data;
    } catch (err) {
      // console.log(err);
      err.response &&
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  };

export const savePatientImmigration =
  (patient_id, immigration_id, formData, history) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      dispatch({ type: REMOVE_ERRORS });

      let res = null;
      if (immigration_id) {
        res = await axios.put(
          `/api/patient/immigration/${patient_id}/immigration/${immigration_id}`,
          formData,
          config
        );
      } else {
        res = await axios.post(
          `/api/patient/immigration/${patient_id}/immigration`,
          formData,
          config
        );
      }

      if (res.data.status === true) {
        dispatch(setAlert("Patient immigration data saved.", "success"));
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_IMMIGRATION_UPDATED,
        });
      }

      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  };

export const getPatientImmigrationData = (patient_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    dispatch({ type: REMOVE_ERRORS });

    const res = await axios.get(
      `/api/patient/immigration/${patient_id}/immigration`,
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: FRONTSTAFF_EMPLOYEE_IMMIGRATION_DATA_UPDATED,
        payload: res.data.response,
      });
    }

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch({
        type: FRONTSTAFF_EMPLOYEE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

export const getImmigrationFormKeys =
  (patient_id, language) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      dispatch({ type: REMOVE_ERRORS });
      const res = await axios.get(
        `/api/patient/immigration/${patient_id}/immigration/keys?language=${language}`,
        config
      );

      dispatch({
        type: FRONTEMPLOYEE_IMMEGRATION_FORM_KEYS_UPDATED,
        payload: res.data.response,
      });

      return res.data;
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: FRONTSTAFF_EMPLOYEE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  };
