import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  CardBody,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import { Alert as ShowAlert } from "reactstrap";

import { getConsentFormContentWeb } from "actions/front/Employer/EmployerProfile";
import { replaceTemplateContent } from "utils/nodeHelper";

const ConsentForm = ({
  getConsentFormContentWeb,
  formData,
  setFormData,
  currentEmployee,
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const [consentFormText, setConsentFormText] = useState(null);
  useEffect(() => {
    if (!modal || consentFormText) return;

    getConsentFormContentWeb().then((response) => {
      prepareConsentForm(response);
    });
  }, [modal]);

  const prepareConsentForm = (response) => {
    const organization = currentEmployee?.employer.length
      ? currentEmployee?.employer[0]
      : {};
    const preSignTextReplaced = response?.preSignatureText.map((text) =>
      replaceTemplateContent(text, {
        employerName: organization?.companyName,
      })
    );
    const postSignTextReplaced = response?.postSignatureText.map((text) =>
      replaceTemplateContent(text, {
        employerName: organization?.companyName,
      })
    );

    const getConsentFormTemplateContent = {
      ...response,
      preSignatureText: preSignTextReplaced,
      postSignatureText: postSignTextReplaced,
    };

    setConsentFormText(getConsentFormTemplateContent);
  };

  const [signature, setSignature] = useState(null);

  const [alert, setAlert] = useState(null);
  const onClickConfirmHandler = (e) => {
    e.preventDefault();
    if (signature.isEmpty()) {
      setAlert("Signature required.");
      return;
    }

    setFormData({
      ...formData,
      signatureImgURL: signature.getTrimmedCanvas().toDataURL("image/png"),
    });
    toggleModal();
  };

  return (
    <CardBody>
      <Row>
        <Col md="12">
          <p>
            Patients are required to sign a consent form to perform the tests.
            Click below button to view and sign the consent form.
          </p>

          <div className="consent-button-section">
            <Button
              outline
              type="button"
              color="secondary"
              onClick={toggleModal}
            >
              View and Sign consent form
            </Button>
          </div>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Consent Form</ModalHeader>
        <ModalBody>
          {consentFormText ? (
            <div style={{ whiteSpace: "pre-wrap" }}>
              <div className="consent-form-content">
                {consentFormText.preSignatureText.map((html, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                ))}

                {alert ? <ShowAlert color={`danger`}>{alert}</ShowAlert> : null}

                <div className="signature-pad-wrapper-style">
                  <SignatureCanvas
                    penColor="blue"
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "signature-canvas",
                    }}
                    ref={(ref) => {
                      setSignature(ref);
                    }}
                  />
                  <button
                    onClick={() => {
                      signature.clear();
                    }}
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      padding: "0 10px",
                    }}
                  >
                    Clear
                  </button>
                </div>

                {consentFormText.postSignatureText.map((html, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                ))}
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onClickConfirmHandler}>
            Confirm and close
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </CardBody>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getConsentFormContentWeb,
})(ConsentForm);
